.activation-alert {
	display: flex;

	&-text {
		flex : 1;
		margin : 0;
		padding: 0.5rem;
		text-align: justify;
	}

	&-form {

		padding-top: 0.5rem;
		&-button {
			width : 100%;
			margin-top: 0.5rem;
		}
	}


}