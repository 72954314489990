@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../../../variables";

.shares {
    padding-top: 1rem;
    border-top: 1pt solid #e6ebf2;

    h6 {
        font-size: 0.75rem;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .shares-table {
        text-align: center;

        .shares-table-header {
            background-color: #e8ecef;
            color: #a1a8af;
            text-transform: uppercase;
            padding: 0.5rem;
            font-size: 9pt;

            @include media-breakpoint-down(sm) {

                display: none;

            }
        }

        .shares-table-row {
            padding: 0.5rem;
            font-size: 9pt;
            color: #5c6971;
            font-weight: bold;
            background-color: #feffff;
            align-items: center;

            @include media-breakpoint-down(sm) {
                padding-top: 2rem;
            }



            &-column {
                margin-top: 0.5rem;

                .shares-table-row-column-info {
                    span {
                        text-align: center;
                    }
                }

                &-info {
                    display: flex;
                    flex-direction: column;
                }

                @include media-breakpoint-up(sm) {
                    span[class$="-subtitle"] {
                        display: none;

                    }
                }

                @include media-breakpoint-down(sm) {

                    padding-left: 0;
                    padding-right: 0;
                    background-color: transparent;

                    .shares-table-row-column-info {
                        font-size: $mobile-middle-font-size;

                        span {
                            text-align: center;
                        }
                    }

                    span[class$="-subtitle"] {
                        color: $weak-gray-color;
                        font-size: $mobile-small-font-size;
                        margin-bottom: 0.5rem;


                    }

                    &-name {
                        order: 1;
                        font-size: $mobile-middle-font-size;
                        margin-bottom: 2rem;
                    }

                    &-tool {
                        order: 2;
                        text-align: right;

                        svg {
                            font-size: 20pt;
                        }
                    }

                    &-diff {
                        order: 3;

                        .shares-table-row-column-info {
                            font-size: $mobile-middle-font-size;

                            span {
                                text-align: left;
                            }
                        }

                    }

                    &-lastprice {
                        order: 4;

                        &.up,
                        &.down {
                            color: $weak-gray-color;
                        }

                    }

                    &-gain__percent {
                        order: 5;

                        .shares-table-row-column-info {

                            .value__up,
                            .value__down {
                                color: $weak-gray-color;
                                background-color: transparent;
                                padding: 0;
                            }
                        }
                    }

                    &-marketValue {
                        order: 6;

                        .shares-table-row-column-info {
                            span {
                                text-align: right;
                            }

                        }

                    }

                    &-count {
                        order: 7;

                        .shares-table-row-column-info {
                            span {
                                text-align: left;
                            }
                        }

                    }

                    &-buyCourse {
                        order: 8;
                    }



                    &-gain__money {
                        order: 9;

                        .shares-table-row-column-info {

                            .value__up,
                            .value__down {
                                color: $weak-gray-color;
                                background-color: transparent;
                            }
                        }
                    }



                    &-buy {
                        order: 10;
                    }

                    &-sell {
                        order: 11;

                    }

                    &-buy,
                    &-sell {
                        margin-top: 1rem;

                        span {
                            color: #212529;
                            //background-color: #f8f9fa;
                            background-color: $background-light-gray;
                            display: block;
                            text-align: center;
                            padding: 0.5rem 0;
                            border-color: #f8f9fa;
                            width: 100%;
                            border-radius: 2px;
                        }

                        /*
					.btn {
						width : 100%;

					}
					*/
                    }

                    &-buy {
                        padding-right: 0.5rem;
                    }

                    &-sell {
                        padding-left: 0.5rem;
                    }




                }

                &.buy,
                &.sell span {
                    cursor: pointer;
                }

                &.buy.disabled,
                &.sell.disabled span {
                    cursor: default;
                    color: #dee2e6;
                }
            }

            .value {
                &__up {
                    background-color: #e5fbf0;
                    color: #009f00;
                    padding: 4px 8px;
                }

                &__down {
                    background-color: #F7E9E9;
                    color: #D24C47;
                    padding: 4px 8px;
                }
            }
        }

        .stocks-table-row-column {
            padding: 0;

            &.order-nav {
                padding-top: 1rem;
                border-top: 1px solid #dee2e6;

            }

            &.order {
                padding-top: 1rem;
                padding-bottom: 1.5rem;
                border-top: 1px solid #dee2e6;

                form {
                    display: flex;
                    align-items: center;

                    button {
                        height: calc(1.5em + 0.75rem + 10px);
                        padding-top: 0;
                        padding-bottom: 0;
                    }

                    .form-group,
                    select {
                        margin: 0 1rem 0 0;

                        label {
                            display: none;
                        }

                    }

                    input {
                        position: relative;

                        &.form-control.is-valid {
                            background-image: none;
                        }

                        &::placeholder {
                            color: #585c62;
                            opacity: 0.5;
                            font-size: 0.5em;
                            position: absolute;
                            top: -100px;
                        }
                    }

                    .invalid-feedback {
                        position: absolute;
                    }
                }

            }
        }
    }

}