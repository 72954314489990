@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../variables";

.portfolios {
    .btn-toolbar>button {
        min-width: 6em;
    }

    .toolbox.container {
        padding: 0;
        margin-top: 1em;
        margin-bottom: 1em;

        @include media-breakpoint-down(sm) {
            padding: 0 0.5rem;

        }

        .controls {
            margin-bottom: 1em;
        }
    }

    .content {
        background-color: #f3f8fc;

        .header {
            background-color: #ebeff5;
            //padding: 2rem;

            @include media-breakpoint-down(sm) {
                padding: 2rem 0;
            }

            .container {
                @include media-breakpoint-down(sm) {
                    padding: 0 0.5rem;
                }
            }
        }
    }

    .card {
        border: none;
        color: #00293c;

        .card-header {
            background: #ebeff5;
            display: flex;

            @include media-breakpoint-down(sm) {
                margin: 0 0.5rem;
                background-color: $background-dark-gray;
                color: $font-color-white;
            }
        }

        .card-body {
            background-color: #eef2f5;

            @include media-breakpoint-down(sm) {
                background-color: $background-white;
            }

        }

        &.competition {
            padding: 0;

            &.active {
                .card-header {
                    background-color: #34383b;
                    color: white;
                }

                .card-body {
                    background: #feffff;
                }

            }

            .competition-name {
                flex-grow: 1;
                border: none;
                align-self: center;
                margin: 0;

                &-badge {
                    margin-left: 1rem;

                    svg {
                        margin-right: 0.5rem;

                    }
                }



            }

            .competition-portfolio {
                &:first-child {
                    margin-top: 0;
                    border-top: none;
                    padding-top: 0;
                }

                &:last-child {

                    padding-bottom: 0;
                }

                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                border-top: 1pt solid #e6ebf2;


                h6 {
                    margin: 0;
                }

                .label-number {

                    //
                    .lds-ring {
                        width: 1rem;
                        height: 1rem;

                        & div {
                            width: 1rem;
                            height: 1rem;
                            margin: 0;
                        }
                    }
                }

                .subtitle {
                    text-transform: uppercase;
                    color: #99a0a7;
                    font-size: 9pt;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                .competition-portfolio-participant {}

                .competition-portfolio-value,
                .competition-portfolio-gain {

                    @include media-breakpoint-down(sm) {
                        margin-top: 2rem;
                    }

                    &.down {
                        color: #e70000;
                    }

                    &.up {
                        color: #009f00;
                    }
                }

                .competition-portfolio-place {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;

                    &-icon {
                        font-size: 1.5rem;
                        margin-left: 0.5rem;
                    }

                    @include media-breakpoint-down(sm) {
                        &-icon {
                            font-size: 2.5rem;
                        }

                        .subtitle {
                            display: none;
                        }
                    }

                }

                @media (max-width: 767.98px) {
                    .competition-portfolio {
                        &-cell {
                            padding-left: 0;
                            padding-right: 0;
                            justify-content: center;
                            display: flex;
                            flex-direction: column;
                        }

                        &-participant {
                            order: 1;

                        }

                        &-place {
                            justify-content: flex-end;
                            flex-direction: row;

                            order: 2;

                            .label-number {
                                margin-right: 1rem;

                            }
                        }

                        &-balance {
                            order: 3;
                            margin-top: 2rem;
                        }

                        &-value {
                            order: 5;
                            align-items: flex-end;
                        }

                        &-gain {
                            order: 4;
                            align-items: center;

                            &.money {
                                display: none;

                            }
                        }
                    }


                }

            }

            .competition-dates {
                align-self: center;
            }
        }



    }
}