@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../variables";
.competitions {
	padding-bottom: 1rem;
	.header {
		.container {
			padding-left: 1rem;
		}
	}
	.competitions-table {



		@include media-breakpoint-down(sm) {
			padding-left: 1rem;
			padding-right: 1rem;

		}	

		.table {
			
			background: rgba($weak-gray-color-1, 0.1);
			margin-bottom: 0;

			&-header {
				@include media-breakpoint-down(sm) {
					display: none;

				}				
			}



			&.table-divider {
				//background: rgba($weak-gray-color-1, 0.1);
				@include media-breakpoint-down(sm) {
					//display: none;

				}					
			}

			&__dark {
				background-color: $background-dark-gray;
			}

			.table-column-cell {
				display: flex;
				justify-content: flex-start;
				padding : 0.5rem 0.5rem;

				&-expand {
					    justify-content: center;

					font-size: 20pt;
					color: $weak-gray-color-1;
					opacity: 0.5;
					@include media-breakpoint-down(sm) {
						    align-items: center;
						    font-size: 30pt;
					}					
				}	

				&-divider {
					display: block;
				}

				&-name {
					.table-column-cell-content-value {
						@include media-breakpoint-down(sm) {

							font-size: $mobile-header-font-size;
						}						
						
					}
				}

				&-enddate {
					padding-left: 0;
					padding-right: 0;
				}				
				&-startdate {
					padding-right: 0;
				}

				&-portoliovalue {
						@include media-breakpoint-down(sm) {
							display: none;
						}	

					
				}	

				&-costtobuy {
					padding-left: 0;
					.table-column-cell-content-value {
						@include media-breakpoint-down(sm) {
							font-size: $mobile-header-font-size;
						}
					}					
				}

				button {
					//background: rgba($weak-gray-color-1, 0.1);
				}		

			}


			&__selected {

				background-color: $background-white;

				.table-divider {
					background: $background-white;

				}				

				&__top {
					//padding-top: 1rem;	
				}

				.table-column-cell {
					&-expand {
						opacity: 1;
						svg {
							color : $font-color-red;
							
						}
					}
				}
			}



		}

		.table-competition-content {
			

			.table-column-cell {
				&-content {
					display: flex;
					flex-direction : column;
					align-items : center;
					height: 100%;
					justify-content: space-between;		

					&-label {
						color: $weak-gray-color-1 ;
					}	
				}

				&-puff {
					@include media-breakpoint-down(sm) {
						sdisplay: none;

					}
				}

				p {
					color: $dark-gray-color ;
				}

			}
			.table-competition-content-cell {

				&-moreinfo, &-prize {
					display: block;


				}
				&-prize  {
					.table {
						background : none;
						color: $weak-gray-color-1 ;


					}
					.table-competition-prize {
						&-cell {
							&-place {
								margin-right: 1rem;
							}

							&-prize {
								color: $dark-gray-color;
							}
						}
					}
				}
				&-courtage {
					justify-content: center;	
				}

				&-startdate {
					padding-left : 0;
				}				
				&-enddate {
					padding-right : 0;
				}




				&-status {
					.badge-success {
						text-transform: uppercase;
						font-weight: normal;
						padding: 0.2rem;
    					border-radius: 2pt;
					}

				}

			}
		}
	}
}