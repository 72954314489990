.support {
  margin-bottom: 32px;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  > .container {
    padding: 0 42px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
  }

  &-header {
    margin-bottom: 32px;

    h1 {
      font-size: 24px;
      font-weight: 400;
    }
  }

  .error-feedback {
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 80%;
    color: #dc3545;
  }

}