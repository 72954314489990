.footer {
    background: #282828;
    color: white;

    .footer-links {
        padding: 52px 0 52px 0;
        span {
            font-size: 18px;
            font-weight: bold;
        }   
        .footer-links-body {
            margin-top: 24px;
        }  

           p, a {
            &:hover {
            text-decoration: none;
            }
            color: rgba(255, 255, 255, 0.4);
        }
        .col-6{padding-left: 0px;}
    }

    .footer-contacts {
        padding: 32px 0 32px 0;
        .footer-contacts-line {
            display: flex;
            flex-direction: column;
            margin-top: 12px;
            margin-bottom: 12px;
        }

    }

    .footer-divider {
        height: 1px;
        width: 100%;
        background: rgba(255, 255, 255, 0.1);
    }

    .footer-icon-container {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
    } 

    .footer-social-icon-container {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;
        margin: 4px;
        border-radius: 16px;
        background: #393b40;
    }
    .footer-icon {

        background-size: contain;
    }  

    .footer-logo-icon-container {
        width: 60px;
        height: 60px;
        margin-top: -24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 4px;
    }
    .attention {
        color: red;
    }    
}
