@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../variables";

#top {
    .logo {
        width: 4.2rem;
        margin-left: 1rem;
    }

    .toast {
        position: fixed;
    }

    ul.navbar-nav {
        .nav-item-name {
            display: flex;
            align-items: center;

            &-icon {
                font-size: 1rem;
                color: $background-dark-gray;
                cursor: pointer;
            }
        }
    }
}






.nav-link {

    &.active {
        color: $font-color-red !important;
    }

    .navbar-light .navbar-toggler {
        margin-right: 1rem;
    }

    .akt-navbar-brand {
        margin-left: 1rem;
    }

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        @include media-breakpoint-down(md) {
            flex-direction: row;
            justify-content: start;

            img {
                margin-right: 1rem;
            }

        }

        img {
            height: 34px;
            width: 34px;
            margin-bottom: 8px;
        }

    }
}

.akt-navbar {
    margin-bottom: 40px;
}

@include media-breakpoint-down(sm) {
    .navbar-collapse {
        background-color: #fff;
        padding: 0.5rem 2rem;
    }

    .navbar {
        padding: .5rem 0rem;
    }

    .navbar-toggler {
        margin-right: 1rem;
    }

    .logo {
        margin-left: 1rem;
    }
        .tooltip {
            display: none;
        }
    #top {


        ul.navbar-nav {
            .nav-item-name {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &-icon {
                    font-size: 2rem;
                    order : 1;

                }
            }
        }
    }



}

@include media-breakpoint-up(lg) {

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 6px;
        padding-left: 6px;
        margin-left: 1rem;
        margin-right: 1em;
    }

    .nav-link.active {
        border-bottom: 4px solid $background-red;
    }

    .navbar-collapse {
        margin-bottom: 26px;
    }

}