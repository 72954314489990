@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../variables";

.stocks {
    .title {
        padding-bottom: 2rem;

        h6 {
            margin-right: 1rem;
            margin-bottom: 0;
        }
    }

    .stocks-table {
        margin-bottom: 2rem;

        .spinner {
            text-align: center;
            padding: 1rem;
        }

        .stocks-table-header {

            background-color: #22252a;
            color: #fff;
            text-transform: uppercase;
            padding: 0.5rem;

            .stocks-table-header-column {
                padding: 0;
                text-align: right;

                &.buy, &.share {
                    text-align: left;


                }

            }
        }

        .stocks-table-row {
            color: #585c62;
            padding: 1rem .3rem;
            border-bottom: 1px solid #f2f4f5;
            align-items : center;

            .stocks-table-row-column {
                padding: 0;
                text-align: right;


                &.buy,&.share {
                    text-align: left;
                }


                &.order-nav {
                    padding-top: 1rem;
                    border-top: 1px solid #dee2e6;

                }

                &.order {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    border-top: 1px solid #dee2e6;

                    form {
                        display: flex;
                        align-items: center;

                        button {
                            height: calc(1.5em + 0.75rem + 10px);
                            padding-top: 0;
                            padding-bottom: 0;
                        }

                        .form-group,
                        select {
                            margin: 0 1rem 0 0;

                            label {
                                display: none;
                            }

                        }

                        input {
                            position: relative;

                            &.form-control.is-valid {
                                background-image: none;
                            }

                            &::placeholder {
                                color: #585c62;
                                opacity: 0.5;
                                font-size: 0.5em;
                                position: absolute;
                                top: -100px;
                            }
                        }

                        .invalid-feedback {
                            text-align: left;
                            position: absolute;
                        }

                        .valid-feedback {
                            position: absolute;
                        }
                    }

                }
            }

            .buy-button {
                padding: 0.1rem 1rem;
                background-color: #2ba812;
                border-radius: 3px;
                color: white;
                cursor: pointer;

                &.disabled {
                    cursor: default;
                    background-color: #dee2e6;
                    color: white;
                }

            }

            .percent-change {
                .value {
                    &__up {
                        padding: 4px 6px;
                        background-color: #e0f6ee;
                        border-radius: 3px;
                        color: #2ba812;
                    }

                    &__down {
                        padding: 4px 6px;
                        background-color: #F7E9E9;
                        border-radius: 3px;
                        color: #D24C47;
                    }
                }

            }

            &.selected {
                background-color: white;
                -webkit-box-shadow: 10px 10px 26px 0px rgba(0, 0, 0, 0.38);
                -moz-box-shadow: 10px 10px 26px 0px rgba(0, 0, 0, 0.38);
                box-shadow: 10px 10px 26px 0px rgba(0, 0, 0, 0.38);

                .tool {
                    margin-bottom: 1rem;
                }

                .nav {

                    border-bottom: 1pt solid #f2f4f5;

                    .nav-link {
                        font-weight: bold;
                        color: #585c62;

                        &.active {
                            padding: 0.5rem 0rem;
                            color: #ea3839;
                            border-bottom: 3pt solid #ea3839;

                        }
                    }
                }


            }


        }

        .tool {
            text-align: center;
        }

        .share {
            font-weight: bold;
        }
    }
}

.result-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 92%;
    font-weight: bold;

    &-success {
        color: #28a745;
    }

    &-failed {
        color: #dc3545;
    }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
    .stocks {

        .stocks-table {

            .stocks-table-header {
                .buy {
                   // display: none;

                }

                .share {
                    text-align: center;

                }

                .precent-change {
                    text-align: center;

                }

                .change {
                    display: none;


                }

                .last {}

                .buy-price {
                    display: none;


                }

                .sell-price {
                    display: none;


                }

                .highest {
                    display: none;


                }

                .lowest {
                    display: none;


                }

                .volume {
                    display: none;


                }

                .tool {}
            }


            .stocks-table-row {

                .stocks-table-row-column {
                    &.order-nav {
                        display: none;
                    }

                    &.order {
                        form {
                            display: flow;
                            flex-direction: column;
                            align-items: stretch;

                            .form-group {
                                label {
                                    display: block;
                                    margin-top: 0.5rem;
                                }

                                button {
                                    margin-top: 1rem;
                                    font-size: 1rem;

                                    width: 100%;
                                }
                            }


                        }
                    }

                }

                padding-right : 0;

                .buy {
                   // display: none;

                }

                .share {
                    text-align: left;

                }

                .percent-change {
                    .value {
                        padding: 0.1rem 0.5rem;
                    }
                }

                .change {
                    display: none;


                }

                .last {
                	text-align: center;
                }

                .buy-price {
                    display: none;


                }

                .sell-price {
                    display: none;


                }

                .highest {
                    display: none;


                }

                .lowest {
                    display: none;


                }

                .volume {
                    display: none;


                }

                .tool {}




            }

        }
    }
}

@include media-breakpoint-down(sm) {
    .container {

        padding-right: 0;
        padding-left: 0;




    }
}