@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "variables";

.container {
	
	@include media-breakpoint-down(sm) {

		.form-row > [class*="col-"] {
			padding-right: 0;
			padding-left: 0;
		}
	}	
}

.card {
	border: none;
	border-radius: 2px;
	.card-header {
		@include media-breakpoint-down(sm) {
			margin: 0;



		}		
		&:first-child {
			border-radius: $standard-border-radius;
			cursor: pointer;
		}
		&__dark {
			    background-color: $background-dark-gray;
    			color: white;
		}
	}

	margin-bottom: 1rem;
}

.row {
	margin-left: 0;
	margin-right: 0;
}

.table {
	padding : 0 0.5rem;
	@include media-breakpoint-down(sm) {
		padding-top : 1rem;
		padding-bottom : 1rem;

	}	
	&__selected {
		/*
		@include media-breakpoint-down(sm) {
			border-radius: 2pt;
			-webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
			-moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
			box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
		}	
		*/	
		


	}
	.table-column {
		&-header {
    		color: $weak-gray-color-1;
    		text-transform: uppercase;
    		padding: 0.5rem .5rem;
    		font-size: 9pt;			
		}		

		&-cell {
			    padding-left: 1rem;
			    justify-content : center;
				align-items: center;	
			    @include media-breakpoint-down(sm) {
					align-items: stretch;
				}

		}
		&-cell-content {
			display: flex;
			flex-direction: column;
			@include media-breakpoint-down(sm) {
				justify-content: space-between;
			}
			        


			&-label {
				@include media-breakpoint-up(sm) {
					display: block;
				}
				text-transform: uppercase;
				color : $weak-gray-color-1;
				text-align: center;
			}
			&-label2 {
				@include media-breakpoint-up(sm) {
					display: none;
				}
				text-transform: uppercase;
				color : $weak-gray-color-1;
				text-align: center;
			}
			&-value {
				font-size: $mobile-middle-font-size;
			}
		}
	} 

	&__dark {
				
		background-color: $background-dark-gray;

		.table-column {
			&-header {
    			color: white;
			}
			&-cell {
			}
		} 
	} 
}

form {
	textarea, input {
		border-radius: $standard-border-radius;
	}

}

.content {
	.header {
		.container {
			padding :0 0.5rem;
		}

	}
}


.btn {
  border-radius: 2px;
    text-transform: uppercase;
    padding: 10px 20px;
    font-size: 0.75rem;
    line-height: 0.75rem;
    font-weight: 500;  

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
      padding: 2rem;
    }     
    &.btn-primary {
      background: #ea373a;
      color: #fff;
      border: none;
      text-shadow: none;

 
  }
  &.btn-light {
    background: rgba($weak-gray-color-1, 0.1);
    border-color:  rgba($weak-gray-color-1, 0.1);
    color:  $weak-gray-color-1;
  }  
}
.btn.btn-wide {
  padding: 20px 30px;
}
.btn.btn-inactive {
  background: #868d93;
}