.btn-animated {
	display: flex;
	justify-content : center;
	align-items : center;
	padding: 1rem;

	.spinner {
		position: absolute;
		left: 1rem;
		margin-right: 1rem;
	}

	&-text {
		margin-left: 1rem;
		margin-right: 1rem;
	}
}