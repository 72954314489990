$dark-gray-color : #282d35;
$light-gray-color : #b5b6b6;;

$weak-gray-color : #b5b6b6;;
$weak-gray-color-1 : #b5b6b6;



$font-color-dark-grey : #172b4d;
$font-color-white : white;
$font-color-red : #ea373a;


$background-dark-gray : #22252a;
$background-light-gray : #eef2f5;
$background-white : #feffff;
$background-red : #ea373a;

$big-font-size : 38pt;
$middle-font-size : 17pt;
$small-font-size : 16pt;

$mobile-big-font-size : 38pt;
$mobile-header-font-size : 18pt;
$mobile-middle-font-size : 12pt;
$mobile-small-font-size : 6pt;

$standard-border-radius : calc(0.15rem - 1px) calc(0.15rem - 1px) 0 0;

