@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../variables";

.purchase {

    .error-feedback {
        width: 100%;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-size: 80%;
        color: #dc3545;
    }


    .purchase_swish {
        &__exclamation {
            color: red;
            font-size: 1rem;
            margin-right: 1rem;
        }

        &__complete {
            display: inline;
        }

    }



    a {
        margin-right: 0.5rem;
        margin-left: 0.5rem;

        &:hover {
            text-decoration: none;
        }
    }



    input[type="checkbox"] {
        margin-right: 1rem;
    }

    .header {
        .container {
            display: flex;

            .left {
                display: flex;
                justify-content: center;
                align-self: center;
                padding: 8px;
                cursor: pointer;
                color: #000;

                svg {
                    width: 32px;
                    height: 32px;
                }
            }

            .text {
                justify-content: center;
                align-self: center;

                h5 {
                    margin-bottom: 0;
                }
            }
        }
    }

    .order-summary {
        &-amount-form {
            display: flex;
            align-items: center;

            .form-group {
                margin-bottom: 0;
            }

            &-amount {
                line-height: 1rem;
                height: 1.5rem;
                width: 2rem;
                border-radius: $standard-border-radius;
                margin: 0 0.5rem;

                &__plus,
                &__minus {
                    color: $weak-gray-color-1;
                    opacity: 0.3;
                    font-size: 20pt;
                }

            }
        }

        .table {

            .table-column-header,
            .table-column-cell {
                padding: 0;
            }

            .table-column-header {
                &-amount {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                &-cost {
                    text-align: right;

                }

                &-enddate {
                    text-align: center;

                }

            }

            .table-column-cell {
                &-amount {
                    justify-content: flex-end;


                    display: flex;

                    @include media-breakpoint-down(sm) {
                        justify-content: center;
                        margin-top: 1rem;
                    }
                }

                &-cost {
                    text-align: right;

                }

                &-enddate {
                    text-align: center;
                }
            }
        }
    }

    .payment-method {

        &-title {
            display : flex;

            .spinner {
                margin-left: 1rem;
            }
        }

        .payment-method-nav {
            margin-bottom: 2rem;

            .payment-method-nav-item-cards {
                display: flex;
                align-items: stretch;

                a {
                    display: flex;
                    align-items: center;
                }

            }

            .payment-method-nav-item {

                display: flex;
                justify-content: center;
                align-self: center;
                padding-bottom: 24px;

                &-active {
                    border-bottom: 2pt solid $font-color-red;
                }

                img {
                    width: 180px;
                    height: 30px;
                }
            }
        }

        .swish-logo {
            width: 60pt;
        }

        &-body {
            padding: 1rem;
            background: rgba($background-white, 0.1);

            @include media-breakpoint-down(sm) {
                padding-left: 0.5rem;
                padding-right: 0.5rem;

            }

            &-form {
                &-submit {
                    width : 6rem;
                }
                .form-group {
                    //margin-bottom: 0;


                    &.payment-method-body-form-group-agree {
                        display: flex;
                        justify-content: space-between;

                        .custom-checkbox {
                            .custom-control-label::before {
                                top: 0;
                            }



                        }

                        @include media-breakpoint-down(sm) {
                            flex-direction: column;

                            button {
                                margin-top: 1rem;
                            }


                        }
                    }

                    textarea,
                    input {
                        background: transparent;
                        //opacity: 0.1;

                        &:focus {
                            background: $background-white;
                        }

                    }
                }



            }
        }
    }

    .order-receipt {
        padding: 2rem;

        .subtitle {}

        .table {
            .table-column-cell {
                padding: 0.5rem 0;
                color: $weak-gray-color-1;
            }

            .table-column-cell-amount {
                display: flex;
                justify-content: flex-end;
            }

            .table-column-cell-total {
                color: $font-color-red;
                font-weight: bold;
                text-align: right;

            }

            .table-column-cell-subtotal,
            .table-column-tax {
                color: $font-color-dark-grey;
                font-weight: bold;
                text-align: right;


            }
        }
    }

    @include media-breakpoint-down(sm) {
        container {
            h5 {
                margin-left: 1rem;
            }
        }

    }
}
