@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../../../variables";
.duel {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .versus {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: bold;
        padding: 0.5rem;
    }

    @include media-breakpoint-down(sm) {
    	flex-direction: column;

    }

}