.competition-form {
    display: flex;
    flex-direction: column;

    input {}

    &-datepicker {
        .react-date-picker__wrapper {
            border: none;
        }

    }


}