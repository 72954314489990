@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../variables";

.qa {
    padding-top: 2rem;
    margin-bottom: 2rem;

    .card {
        margin-bottom: 0;
        border: 1px solid rgba(0, 0, 0, 0.125);

    }

}

.terms-and-conditions,
.rules,
.cookies,
.privacypolicy,
.qa,
.team {
    background: white;
    color: $dark-gray-color;
    margin-top: 50px;
    width: 70%;

    h5 {
        font-size: 1.25rem;
        color: $dark-gray-color;


    }

    ul {
        //color : $dark-gray-color-1;
        color: 'grey';
        padding-left: 0;
        list-style-type: none;

        &.circled {
            margin-left: 2rem;
            list-style-type: circle;
        }

        .title {
            color: $dark-gray-color;
            font-weight: bold;
        }
    }

    ol {}

    .example {
        background-color: #EBF5FB;
        padding: 1rem;
        margin-bottom: 1rem;

        p {
            margin-bottom: 0;
        }
    }



    @include media-breakpoint-down(sm) {
        padding-left: 1rem;
        padding-right: 1rem;
        width: 92%;

        h5 {
            font-size: $mobile-header-font-size;
        }

        ul {
            padding-left: 0;

            li {
                font-size: $mobile-middle-font-size;
                text-align: justify;
            }

        }
    }



}