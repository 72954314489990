@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../variables";

.editable-header {
	display: flex;
	input {
		line-height: 1.4rem;
	}
	.editable-header-icon {
		font-size: 10pt;
		cursor: pointer;

		&__close {
			position: absolute;
			right : 1.5rem;
			top : 0.5rem;
		}
	}	
}