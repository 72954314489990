@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.register {
    &-content {
        &-password {
            position: relative;
            svg {
                position : absolute;
                top : 12px;
                right: 2rem;
                font-size: 1rem;
            }

        }

        .aggreed {
            &-text {
                margin-left: 1rem;
            }
        }

        .aggreed.invalid-feedback {
                display: block;
            
        }
    }


}

.register .form-control:focus {
    border-color: #90cc8c;
    box-shadow: 0 0 0 1px rgba(144, 204, 140, 1);
}

.register .register-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.register .register-content {
    padding-bottom: 24px;
}

.register .register-pre-title {
    color: white;
    background-image: url('../../assets/imgs/slider2.jpg');
    padding: 52px 0 52px 0;
    margin-bottom: 36px;
}

.register .register-content .error-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}


.register {
    .container {
    
    @include media-breakpoint-down(sm) {
        padding-right: 1rem;
        padding-left: 1rem;

    }   
    }
}

