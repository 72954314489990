@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.competitions-code {
  display: flex;
  align-items: center;

  &-button {
    margin-left: 1rem;
  }
}
