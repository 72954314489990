@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "variables";

html {
    background: #ffffff;
    margin: 0;
    padding: 0;
    font-size: 20px;
    color: #2a2e37;
}

@media (max-width: 767px) {
    html {
        font-size: 15px;
    }
}

body {
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}


h5,
h4,
h3,
h2,
h1 {
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

h4>span,
h2>span,
h1>span {
    color: #ea373a;
    font-weight: 700;
}

h1 {
    font-size: 2rem;
}

h1>span {
    font-size: 4rem;
}

h4 {
    font-size: 1.1rem;
}

h4>span {
    font-size: 2.5rem;
}

/*.container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}*/
#top {
    padding-top: 10px;
    padding-bottom: 10px;
    background: #ffffff;
    height: 80px;
}

#top nav {
    display: flex;
    justify-content: space-between;
}

#top nav .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(234, 55, 58, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

#top nav #logo {
    flex: 1 1 auto;
}

/*#top nav ul {
  flex: 1 1 auto;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}*/
#top nav ul li {
    display: block;
}

#top nav ul li a {
    display: block;
    color: #2a2e37;
}

header {}

header .slider {}

header .slider ul {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #212529;
}

header .slider ul li {
    margin: 0;
    padding: 80px 0;
    background-position: center;
    background-size: cover;
}

header .slider .slider-list {
    background-image: url('./assets/imgs/slider_new.jpg');
}

header .slider ul li .text {
    font-weight: 500;
    font-size: .85rem;
    text-shadow: 3px 3px 8px rgba(255, 255, 255, 0.82);
    background-color: rgba(255, 255, 255, 0.6);
    padding: 10px;
}

header .slider ul li form {
    display: block;
    background: #ffffff;
    color: #22252c;
    margin-top: 15px;
}

header .slider ul li form header {
    background: #22252c;
    color: #fff;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.9rem;
    line-height: 0.9rem;
}

header .slider ul li form .form {
    padding: 25px 35px;
}

header .slider ul li form .form h4 {
    text-align: center;
}

header .slider ul li form .form .help {
    margin-top: 20px;
    text-align: center;
    color: #a2a2a2;
}

header .slider ul li form .form .btn {
    width: 100%;
}

.akt-form-control {
    background-color: #f7f7f7 !important;
    border: solid 1px #e2e3e5;
    font-size: 0.9rem;
    margin-bottom: 0.9rem;
    border-radius: 2px;
    padding: 15px;
}

.akt-form-control[name="username"] {
    background-image: url("assets/imgs/field-name.jpg");
    background-position: 10px 50%;
    background-repeat: no-repeat;
    padding-left: 45px;
}

.akt-form-control[name="password"] {
    background-image: url("assets/imgs/field-pass.jpg");
    background-position: 10px 50%;
    background-repeat: no-repeat;
    padding-left: 45px;
}

.competition {
    background: #fff;
    padding-top: 100px;
    padding-bottom: 100px;

    header {
        text-align: center;
        margin-bottom: 50px;
    }
}

.competition .items {}

.competition .items ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.competition .items ul li {
    margin: 0;
    flex: 1 1 auto;
    display: flex;
}

.competition .items ul li .item {
    box-shadow: 0 0 0 1px rgba(241, 241, 241, 1);
    border-radius: 4px;
    display: flex;
    overflow: hidden;
    flex: 1 1 auto;
    transition: box-shadow 0.3s;
    margin-bottom: 10px;
}

.competition .items ul li .item .more {
    font-weight: 500;
    color: #868d93;
    text-transform: uppercase;
}

.competition .items ul li .item .date {
    background: #3a3f38;
    color: #fff;
    padding: 25px 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    text-transform: uppercase;
    font-size: .6rem;
    transition: background 0.3s;
}

.competition .items ul li .item .date .appost,
.competition .items ul li .item .date .number {
    font-size: 1rem;
    font-weight: 500;
}

.competition .items ul li .item .text {
    flex: 1 1 100%;
    padding: 25px 20px;
}

.competition .items ul li .item:hover,
.competition .items ul li .item.active {
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.15);
}

.competition .items ul li .item:hover .date,
.competition .items ul li .item.active .date {
    background: #3f9319;
}








.winners {
    background: #fff;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
}

.winners header {
    text-align: center;
    margin-bottom: 50px;
}

.winners .items {}

.winners .items ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.winners .items ul li {
    margin: 0;
    flex: 1 1 auto;
    display: flex;
}

.winners .items ul li .item {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 40px;
    text-align: center;
    margin-bottom: 25px;
}

.winners .items ul li .item .photo {
    width: 115px;
    height: 115px;
    border-radius: 100%;
    border: 6px solid #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
    /*overflow: hidden;*/
    margin: 0 auto 25px;
    position: relative;
}

.winners .items ul li .item .photo .number {
    position: absolute;
    height: 40px;
    width: 40px;
    right: -10px;
    top: -10px;
    background: #fff;
    font-weight: 500;


    border-radius: 100%;
    flex: 0 0 auto;
    line-height: 40px;
    text-align: center;
    color: #5b6268;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.15);
    transition: all 0.3s;
}

.winners .items ul li .item .photo img {
    width: 100%;
    border-radius: 100%;
}

.winners .items ul li .item .name {
    font-size: 1rem;
    font-weight: 500;
    color: #27282c;
}

.winners .items ul li .item .age {
    margin-bottom: 20px;
    color: #7a7e87;
    text-transform: uppercase;
    font-size: 0.5rem;
}

.winners .items ul li .item .category {
    color: #5c5d5f;
    margin-bottom: 20px;
}

.winners .items ul li .item .text {
    font-weight: 200;
    font-size: .75rem;
    font-style: italic;
}

.winners .items ul li .item.first .photo .number {
    background: #ef9d0d;
    color: #fff;
}

.winners .items ul li .item.first .photo .number:before {
    display: inline-block;
    content: url("assets/imgs/first-winner.jpg");
    line-height: 1px;
    vertical-align: middle;
}

.winners .items ul li .item.second .photo .number {
    background: #3f9319;
    color: #fff;
}

.winners .items ul li .item.third .photo .number {
    background: #3063b2;
    color: #fff;
}

.f {
    border-radius: 50%;
    background-color: #adb4bc;

}


.label-number {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid white;
    margin: 2px;
    background: #adb4bc;
    min-width: 30pt;
    min-height: 30pt;
    padding: 0;
    box-shadow: 0 0 2px #adb4bc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
}

.down {
    color: #e70000;
}

.up {
    color: #009f00;
}

.underlined {
    border-bottom: 1pt solid $weak-gray-color-1;
}


// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
    body {
        font-size: 1rem;
        font-weight: 400;
    }
    header .slider .slider-list {
    background-image: url('./assets/imgs/slider_new_mobile.jpg');
    }
}


.lds-ring {
    display: inline-block;
    position: relative;
    width: 1rem;
    height: 1rem;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 1rem;
    height: 1rem;

    border: 6px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.akt {
    min-height: 100vh;
}

.invalid {
    color: red
}

.badgeinfo {
    font-size: 1rem;
    margin-top: 2rem;
    white-space: normal;
}

@import 'components';