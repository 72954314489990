@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../../variables";

.leaders {
    background: #f3f4f6;
    padding-top: 100px;
    padding-bottom: 50px;

    header {
        text-align: center;
        margin-bottom: 50px;
    }

    .items {

        ul {
            list-style: none;
            padding: 0;
            display: flex;
            flex-wrap: wrap;

            li {
                margin: 0;
                flex: 1 1 auto;
                display: flex;

                .item {
                    background: #fff;
                    flex: 1 1 auto;
                    display: flex;
                    flex-direction: row;
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
                    border-radius: 2px;
                    margin-bottom: 15px;
                    justify-content: center;
                    align-items: center;
                    align-content: space-between;
                    padding: 25px;
                    transition: all 0.3s;



                    &:hover,
                    &.active {
                        box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.15);
                    }

                    &:hover .number,
                    &.active .number {
                        background: #ea3838;
                        color: #fff;
                        box-shadow: none;
                    }

                    &:hover .value,
                    &.active .value {
                        color: #ea3838;
                    }

                    &.first .number:before {
                        display: inline-block;
                        content: url("../../../assets/imgs/first.jpg");
                        vertical-align: middle;
                        line-height: 1px;
                    }

                    &.first .number {
                        background: #2aa912;
                        box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.15);
                        color: #fff;
                    }


                    .number {
                        border-radius: 100%;
                        background: #fff;
                        flex: 0 0 auto;
                        height: 40px;
                        width: 40px;
                        line-height: 40px;
                        text-align: center;
                        color: #5b6268;
                        box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.15);
                        transition: all 0.3s;
                        font-weight: 500;
                    }

                    .text {
                        flex: 1 0 auto;
                        padding: 0 25px;

                        h4 {
                            max-width: 13rem;
                            overflow: hidden;
                            font-size: 1rem;
                        }


                        p {
                            margin-bottom: 0;
                        }
                    }

                    .value {
                        flex: 0 0 auto;
                        font-size: 1.4rem;
                        font-weight: 500;
                        color: #58656d;
                        transition: all 0.3s;
                    }

                }
            }
        }




    }


    @include media-breakpoint-down(sm) {
        .items {
            ul {
                li {
                    .item {

                        .text {

                            h4 {
                                max-width: 6rem;
                            }

                        }

                    }
                }

            }
        }
    }

}